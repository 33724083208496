import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    Switch, Box, Divider, TextField
} from "@mui/material";
import {compose} from "redux";
import {connect} from "react-redux";
import {columnActions} from "../../store/actions/columnAction";
import {styled} from "@mui/system";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend'
import {DnDColumnsContainer} from "./DnDColumnsContainer";
import {Add, Lock} from "@mui/icons-material";
import AddCustomColumn from "../custom-column/AddCustomColumn";
import Spinner from "../spinner/Spinner";

export const DescriptionBox = styled(Box)(({theme}) => ({
    fontSize: 12,
    color: theme.palette.text.disabledLight
}));

function ColumnSettings(props) {
    const {onClose, columns, open, report, columnGroup, setCampaignSelectedColumns, updateColumnVisibility} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const down950 = useMediaQuery(theme.breakpoints.down(950));

    const {data, campaignSelectedColumns} = props?.state?.column;

    const [addCustomColumn, setAddCustomColumn] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const handleChange = event => {
        setSearchQuery(event.target.value);
    };

    const filteredTemps = !searchQuery
        ? props?.state?.column?.data[columnGroup]
        : props?.state?.column?.data[columnGroup]?.filter(item =>
            item?.title?.toLowerCase().includes(searchQuery.toLocaleLowerCase())
        );

    const handleOnCloseAddCustomColumnForm = () => {
        setAddCustomColumn(false);
    };

    const handleOnClickToOpenAddCustomColumnForm = () => {
        setAddCustomColumn(true);
    };

    const handleOnChange = (column) => {
        let hide = !column.hidden; // Toggle the hidden state

        for (const col of columns) {
            if (col?.key === column?.columnKey) {
                col.hidden = hide;
            }
        }

        let selectedCols;
        // Remove selected column if it should be hidden
        if (hide) {
            selectedCols = campaignSelectedColumns.filter(
                (selectedCol) => selectedCol?.columnKey !== column?.columnKey
            );
        } else {
            selectedCols = [...campaignSelectedColumns]; // Keep existing columns

            // Add the column back with updated hidden status
            data[columnGroup]?.forEach((c) => {
                if (c?.columnKey === column?.columnKey) {
                    selectedCols.push({...c, hidden: hide}); // Avoid mutating original object
                }
            });
        }

        setCampaignSelectedColumns(selectedCols);
        updateColumnVisibility({columnID: column?.id, hidden: hide, columnGroup});
    };

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    const handleOnClickApply = () => {
        let settings = [];
        props?.state?.column?.data[columnGroup]?.forEach((defaultCol, defaultIdx) => {
            settings.push({
                name: defaultCol?.columnKey,
                columnId: defaultCol?.id,
                width: defaultCol?.width,
                hidden: true,
                sequenceNumber: defaultIdx
            });
        });


        settings?.forEach((finalCol, finalIdx) => {
            campaignSelectedColumns?.forEach((selectedCol, idx) => {
                if (finalCol?.columnId === selectedCol?.id) {
                    settings[finalIdx].hidden = false;
                    settings[finalIdx].sequenceNumber = idx;
                }
            });
        });

        props?.updateUserColumnSettings({report, settings});
        onClose();
    }

    useEffect(() => {
        let visibleColumns = [];
        data[columnGroup]?.forEach((c) => {
            if (!c?.hidden) {
                visibleColumns.push(c);
            }
        });
        setCampaignSelectedColumns(visibleColumns);
    }, []);

    return (
        <Dialog open={open}
                onClose={handleOnClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="column-settings"
                maxWidth={"lg"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Columns settings</DialogTitle>
            <DialogContent>

                {<AddCustomColumn onClose={handleOnCloseAddCustomColumnForm} open={addCustomColumn}/>}

                {campaignSelectedColumns?.length === 0 && <Spinner overComponentBox={true}/>}

                {campaignSelectedColumns?.length > 0 &&
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        <Box p={1} flexGrow={1} width={300}>
                            <Box sx={{fontSize: 16, fontWeight: 600}}>Columns Visibility</Box>
                            <Divider/>
                            <Box mt={2} mb={2}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    name="columns"
                                    placeholder="Search column"
                                    type="text"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={searchQuery}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box sx={{overflow: "auto", maxHeight: 430, height: "100%"}}>
                                {filteredTemps?.map((item) => {
                                    return (
                                        <Box display="flex" justifyContent="space-between" flexDirection="row"
                                             key={item?.id} p={1} sx={{
                                            borderBottomColor: "background.tableBorder",
                                            borderBottomWidth: "1px",
                                            borderBottomStyle: "solid"
                                        }}>
                                            <Box sx={{minWidth: 100, maxWidth: 100}}>
                                                {item?.title}
                                            </Box>
                                            <DescriptionBox sx={{minWidth: 200, maxWidth: 350}}
                                                            textAlign="left">{item?.description}</DescriptionBox>
                                            <Box>
                                                {item?.columnKey === "name" && <Lock/>}
                                                {item?.columnKey !== "name" &&
                                                    <Switch
                                                        size="small"
                                                        edge="end"
                                                        onChange={() => handleOnChange(item)}
                                                        checked={!item?.hidden}
                                                        inputProps={{'aria-labelledby': item?.id}}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                ml: 4,
                                width: "300px",
                                ...(down950 && {
                                    ml: 0,
                                    width: "100%"
                                })
                            }}
                            p={1}
                            flexGrow={1}
                        >
                            <Box sx={{fontSize: 16, fontWeight: 950}}>Columns Order</Box>
                            <Divider sx={{marginBottom: 2}}/>
                            <Box sx={{overflow: "auto", maxHeight: 500}}>
                                <DndProvider backend={HTML5Backend}>
                                    <DnDColumnsContainer cards={campaignSelectedColumns}
                                                         setCards={setCampaignSelectedColumns}/>
                                </DndProvider>
                            </Box>
                        </Box>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                    <Box flexGrow={1}>
                        <Button startIcon={<Add/>} onClick={handleOnClickToOpenAddCustomColumnForm} color="warning"
                                variant="contained">
                            New Column
                        </Button>
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="noWrap">
                        <Box mr={2}>
                            <Button onClick={handleOnClose} color="inherit" variant="outlined">
                                Close
                            </Button>
                        </Box>
                        <Box>
                            <Button onClick={handleOnClickApply} color="primary" variant="contained">
                                Apply
                            </Button>
                        </Box>
                    </Box>
                </Box>


            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            column: state.column
        }
    }
};

const actionCreators = {
    updateColumnVisibility: columnActions.updateColumnVisibility,
    updateUserColumnSettings: columnActions.updateUserColumnSettings,
    setCampaignSelectedColumns: columnActions.setCampaignSelectedColumns
};

export default compose(connect(mapStateToProps, actionCreators))(ColumnSettings);