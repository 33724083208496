import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    useTheme,
    Box, TextField
} from "@mui/material";
import {compose} from "redux";
import {connect} from "react-redux";
import {columnActions} from "../../store/actions/columnAction";
import {useForm} from "react-hook-form";
import ReactHookFormDropdown from "../input/ReactHookFormDropdown";
import FormulaInput from "./FormulaInput";

function AddCustomColumn(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {onClose, open, createCustomColumn} = props;

    const {register, handleSubmit, setValue, watch, control, setError, clearErrors, formState: {errors}} = useForm();

    const handleOnClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return false;
        }
        onClose();
    };

    const handleOnSubmit = (data) => {
        console.log("submit: ", data);

        data.format = data?.format?.value;
        data.decimals = data?.decimals?.value;

        createCustomColumn(data);
        onClose();
    }

    return (
        <Dialog open={open}
                onClose={handleOnClose}
                fullWidth={true}
                fullScreen={fullScreen}
                aria-labelledby="add-custom-column"
                maxWidth={"md"}
        >
            <DialogTitle sx={{textAlign: "center"}}>Add custom column</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete={"off"}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.name}
                                variant="outlined"
                                margin="dense"
                                id="name"
                                label="Column Name*"
                                {...register("name", {
                                    required: {
                                        value: true,
                                        message: "Column name is mandatory field."
                                    },
                                    maxLength: 100,
                                    minLength: 2,
                                    pattern: {
                                        value: /^[a-z-A-Z\s-_]*$/,
                                        message: "Only letters, space and symbols - _ are allowed"
                                    }
                                })}
                                name="name"
                                size="small"
                                type="text"
                                placeholder="Enter your custom column name"
                                fullWidth={true}
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {errors?.name &&
                                <Box sx={{color: "error.main", fontSize: 13}}>{errors?.name?.message}</Box>
                            }
                        </Box>
                        <Box p={1} width="100%">
                            <TextField
                                error={!!errors.description}
                                variant="outlined"
                                margin="dense"
                                id="description"
                                label="Description"
                                {...register("description", {required: true, minLength: 2, maxLength: 250})}
                                name="description"
                                size="small"
                                type="text"
                                placeholder="Additional info"
                                fullWidth={true}
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        <Box p={1} width="100%">
                            <FormulaInput
                                setValue={setValue} errors={!!errors?.formula} name="formula" label="Formula*"
                                register={register} watch={watch} errorMessage={errors?.formula?.message}
                                setError={setError} clearErrors={clearErrors}
                                columns={props?.state?.column?.data?.campaigns}
                            />
                        </Box>
                        <Box display="flex" justifyContent="stretch" flexDirection="row" flexWrap="wrap">
                            <Box p={1} width="100%">
                                <ReactHookFormDropdown
                                    disableClearable={true}
                                    setValue={setValue}
                                    control={control}
                                    defaultValue={{value: "number", label: "number"}}
                                    name="format"
                                    label="Format"
                                    placeholder="Select format"
                                    options={[
                                        {value: "number", label: "number"},
                                        {value: "percent", label: "percent"},
                                        {value: "currency", label: "currency"}
                                    ]}
                                />
                            </Box>
                            <Box p={1} width="100%">
                                <ReactHookFormDropdown
                                    disableClearable={true}
                                    setValue={setValue}
                                    control={control}
                                    defaultValue={{value: 0, label: "0"}}
                                    name="decimals"
                                    label="Decimals"
                                    placeholder="Select decimal"
                                    options={[
                                        {value: 0, label: "0"},
                                        {value: 1, label: "1"},
                                        {value: 2, label: "2"},
                                        {value: 3, label: "3"},
                                        {value: 4, label: "4"},
                                    ]}
                                />
                            </Box>
                        </Box>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose} color="inherit" variant="outlined">
                    Close
                </Button>
                <Button onClick={handleSubmit(handleOnSubmit)} color="primary" variant="contained">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        state: {
            column: state.column
        }
    }
};

const actionCreators = {
    updateColumnVisibility: columnActions.updateColumnVisibility,
    updateUserColumnSettings: columnActions.updateUserColumnSettings,
    createCustomColumn: columnActions.createCustomColumn,
};

export default compose(connect(mapStateToProps, actionCreators))(AddCustomColumn);