import {apiRequest} from "../helpers/utils";

export const columnServices = {
    fetch,
    updateColumnWidth,
    updateColumnVisibility,
    updateColumnSequence,
    updateUserColumnSettings,
    createCustomColumn
};

function fetch() {
    return apiRequest("get", "v1/column", {}, {});
}

function updateColumnWidth({columnID, width, report}) {
    return apiRequest("patch", "v1/column/" + parseInt(columnID) + "/width", {width: parseInt(width), report});
}

function updateUserColumnSettings({report = "", settings = []}) {
    return apiRequest("patch", "v1/column/settings", {report, settings});
}

function createCustomColumn(data) {
    return apiRequest("post", "v1/column", data);
}

function updateColumnVisibility({columnID, hidden, report}) {
    return apiRequest("patch", "v1/column/" + parseInt(columnID) + "/visibility", {hidden, report});
}

function updateColumnSequence({sourceColumnID, sourceColumnSequence, targetColumnID, targetColumnSequence, report}) {
    return apiRequest("patch", "v1/column/sequence", {
        sourceColumnID,
        sourceColumnSequence,
        targetColumnID,
        targetColumnSequence,
        report
    });
}