import React, {useCallback} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Box} from "@mui/material";
import update from 'immutability-helper'
import {DragHandle, Lock} from "@mui/icons-material";
import {DescriptionBox} from "./ColumnSettings";

const DnDItemCard = ({id, text, subText, lock, moveCard, findCard}) => {
    const originalIndex = findCard(id).index
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'card',
            item: {id, originalIndex},
            canDrag: !lock,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const {id: droppedId, originalIndex} = item
                const didDrop = monitor.didDrop()
                if (!didDrop) {
                    moveCard(droppedId, originalIndex)
                }
            },
        }),
        [id, originalIndex, moveCard, lock],
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'card',
            hover({id: draggedId}) {
                if (draggedId !== id) {
                    const {index: overIndex} = findCard(id)
                    moveCard(draggedId, overIndex)
                }
            },
        }),
        [findCard, moveCard],
    );

    return (
        <Box
            ref={(node) => {
                if (!lock) {
                    drag(drop(node));
                }
            }}
            sx={{opacity: 1, ...(isDragging && {opacity: 0})}}
        >
            <Box display="flex" justifyContent="space-between" flexDirection="row"
                 sx={{
                     p: "4px 8px",
                     backgroundColor: "background.rowHover",
                     mb: 1,
                     cursor: "move",
                     ...(lock && {cursor: "default"})
                 }}
            >
                <Box alignContent="center">{text}</Box>
                <DescriptionBox
                    textAlign="left"
                    alignContent="center"
                >
                    {subText}
                </DescriptionBox>
                <Box alignContent="center" sx={{marginTop: "6px"}}>
                    {lock && <Lock/>}
                    {!lock && <DragHandle sx={{cursor: "move"}}/>}
                </Box>
            </Box>
        </Box>
    );
};

export const DnDColumnsContainer = ({cards, setCards}) => {
    const findCard = useCallback(
        (id) => {
            const card = cards.filter((c) => `${c.id}` === id)[0]
            return {
                card,
                index: cards.indexOf(card),
            }
        },
        [cards],
    );
    const moveCard = useCallback(
        (id, atIndex) => {
            const {card, index} = findCard(id)
            setCards(
                update(cards, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, card],
                    ],
                }),
            )
        },
        [findCard, cards, setCards],
    );
    const [, drop] = useDrop(() => ({accept: 'card'}))

    return (
        <Box ref={drop} display="flex" justifyContent="space-between" flexDirection="column">
            {cards?.map((card) => (
                <DnDItemCard
                    key={card?.id}
                    id={`${card?.id}`}
                    text={card?.title}
                    subText={card?.formula}
                    lock={card?.frozen}
                    moveCard={moveCard}
                    findCard={findCard}
                />
            ))}
        </Box>
    );
}